<template>
  <section id="about" class="section">
    <article class="about-box">
      <div class="columns">
        <div class="column is-6 has-padding">
          <h1 class="title">O Nas</h1>
          <p class="paragraph">
            Od 1989 roku na ulicy Głównej 61, powstaje historia i tradycja
            sklepów technicznych, systematycznie zwiększających zakres swojego
            działania w zaopatrzeniu rynku poznańskiego i wielkopolskiego w
            materiały instalacyjne, elektrotechniczne, budowlane, farby,
            narzędzia oraz osprzęt niezbędny przy realizacji inwestycji
            budowlanych oraz pracach remontowych.
          </p>
          <p class="paragraph">
            Wieloletnie doświadczenie pozwala na zaproponowanie Państwu ciekawej
            i atrakcyjnej formy współpracy, która – mamy nadzieję – sprawi, że
            oferta nasza zostanie przyjęta z aprobatą i zainteresowaniem.
          </p>
          <p class="paragraph">
            Jesteśmy <span class="is-bold">FIRMĄ DLA FIRM</span>, zapraszamy
            jednak wszystkich klientów, którzy potrzebują fachowej obsługi,
            wsparcia technicznego i oczywiście towarów w dobrych cenach.
          </p>
        </div>
        <div class="column is-6">
          <v-lazy-image
            class="image"
            :src="require('@/assets/images/hardware-store.webp')"
            :alt="'hardware-store'"
            width="564"
            height="564"
          />
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import VLazyImage from 'v-lazy-image';

export default {
  name: 'AboutSection',
  components: {
    VLazyImage,
  },
};
</script>

<style lang="scss" scoped>
.column {
  padding: 0 0.75rem;
}

.section {
  margin-bottom: 1.5rem;
}

.about-box {
  margin: 0 auto;
  background-color: $white;
  padding: 0;
  text-align: justify;
}

.paragraph {
  line-height: 1.5;
  font-size: 0.9375rem;
  margin-bottom: 1.5rem;
  color: $grey-moderate;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.has-padding {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.is-bold {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .about-box {
    width: 100%;
  }

  .has-padding {
    padding: 4rem 3rem;
  }
}

@media only screen and (min-width: 769px) {
  .about-box {
    width: 80%;
  }

  .has-padding {
    padding: 0rem 6rem;
  }
}
</style>
